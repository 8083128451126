
import {useState} from 'react'
import './App.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [result, setResult] = useState("");
  const [isTend, setIsTend] = useState(false);
  const [isAudible, setIsAudible] = useState(false);
  const [playerTarget, setPlayerTarget] = useState("");
  const [defenseCall, setDefenseCall] = useState("");
  const [offenseCall, setOffenseCall] = useState("");
  const [offenseTeam, setOffenseTeam] = useState("North Bay");
  const [defenseTeam, setDefenseTeam] = useState("Chicago");

  const roll = async (chartType) => {
    setIsAudible(false);
    setIsTend(false);

    const tendChart = {
      pass: ["LONG PASS", "MEDIUM PASS", "MEDIUM PASS", "SHORT PASS", "SHORT PASS", "SCREEN PASS"],
      normal: ["LONG PASS", "MEDIUM PASS", "SHORT PASS", "OUTSIDE RUN", "OUTSIDE RUN", "INSIDE RUN"],
      run: ["OUTSIDE RUN", "OUTSIDE RUN", "OUTSIDE RUN", "INSIDE RUN", "INSIDE RUN", "INSIDE RUN"]
    }

    const audibleChart = ["LONG PASS", "MEDIUM PASS", "SHORT PASS", "SCREEN PASS", "OUTSIDE RUN", "INSIDE RUN"];

    const offenseChart = {
      normal: ["MEDIUM PASS", "SHORT/SCREEN PASS", "TEND", "OUTSIDE RUN", "INSIDE RUN", "AUDIBLE"],
      pass: ["LONG PASS", "MEDIUM PASS", "TEND", "MEDIUM PASS", "SHORT/SCREEN PASS", "AUDIBLE"],
      run: ["OUTSIDE RUN", "OUTSIDE RUN", "TEND", "INSIDE RUN", "INSIDE RUN", "AUDIBLE"]
    }

    const defenseChart = {
      normal: ["PASS", "SAFE", "SAFE", "SAFE", "RUN", "BLITZ"],
      pass: ["PASS", "PASS", "PASS", "PASS", "BLITZ", "BLITZ"],
      run: ["RUN", "RUN", "RUN", "RUN", "BLITZ", "BLITZ"]
    }

    let offenseRoll = randomIntFromInterval(0,5);
    let defenseRoll = randomIntFromInterval(0,5);
    let targetRoll = randomIntFromInterval(1,20);
    let tendRoll = randomIntFromInterval(0,5);

    // Get the team data
    let dataFile = offenseTeam.replace(" ", "-").toLowerCase() + ".json";
    let response = await axios.get(dataFile);

    let offense = offenseChart[chartType][offenseRoll];
    if(offense == "TEND")
    {
      offense = tendChart[response.data.TEND][tendRoll];
      setIsTend(true);
    }

    if(offense == "AUDIBLE")
    {
      offense = audibleChart[tendRoll];
      setIsAudible(true);
    }

    let defense = defenseChart[chartType][defenseRoll];
    let target = await getPlayer(offense, response.data);

    setOffenseCall(offense);
    setDefenseCall(defense);
    setPlayerTarget(target);
  }

  const changePossession = () =>
  {
    const temp = offenseTeam;
    setOffenseTeam(defenseTeam);
    setDefenseTeam(temp);
  }

  const getPlayer = async(playType, data) =>
  {
    let playerRoll = randomIntFromInterval(1,20);
        
    let chart = playType;
    if(playType.includes("SHORT") || playType.includes("MEDIUM") || playType.includes("SCREEN"))
    {
      chart = "BASIC PASS";
    }

    let players = data[chart];
    console.log('playType', playType);
    console.log('players', players);

    let player = players.find(p=>playerRoll >= p.min && playerRoll <= p.max);
    return player?.name ?? "??";
  }

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <div className="App">
      
      <div>Offense: {offenseTeam}</div>
      <div>Defense: {defenseTeam}</div>

      <div className="d-flex justify-content-center flex-column gap-3 my-5">
        

        <button className="btn btn-primary" onClick={(e) => roll('normal')}>
          Normal (1st or 2nd down, 3rd and short)
        </button>
        
        <button className="btn btn-success" onClick={(e) => roll('pass')}>
          Probable Pass (3rd and long, catch-up, 2-min drill)
        </button>

        <button className="btn btn-danger" onClick={(e) => roll('run')}>
          Short Yardage (4th and short, goal-line, clock-kill)
        </button>

        <button className="btn btn-warning" onClick={(e) => changePossession()}>
          Change Possession
        </button>
        </div>

      {isAudible && <div>[Audible]</div>}
      {isTend && <div>[Tendency Adjustment]</div>}
      <div>{offenseCall}</div>
      {playerTarget && <div>to {playerTarget}</div>}
      {defenseCall && <div>vs. {defenseCall}</div> }
    </div>
  );
}

export default App;
